<template>
<v-app id="app">
    <v-main>
      <v-container fluid>
        <v-bottom-navigation color="#03A9F4"  v-model="view" app>
          <!-- <v-btn @click="bottomNavBar('news')" view="news" height="100%">
            <span>Info Center</span>
            <v-icon>mdi-newspaper-variant-outline</v-icon>
          </v-btn> -->

          <v-btn @click="bottomNavBar('building')" view="building" height="100%">
            <span>Office</span>
            <v-icon>mdi-office-building-marker-outline</v-icon>
          </v-btn>

          <v-btn @click="bottomNavBar('bookings')" view="bookings" height="100%">
            <span>Bookings</span>
            <v-icon>mdi-calendar-month-outline</v-icon>
          </v-btn>

          <!-- <v-btn @click="bottomNavBar('incidentReports')" view="incidentReports" height="100%">
            <span>Incidents</span>
            <v-icon>mdi-message-bulleted</v-icon>
          </v-btn> -->

          <v-btn @click="bottomNavBar('menu')" view="menu" height="100%">
            <span>Menu</span>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-bottom-navigation>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data: () => ({
    view: 'news',
    activeView: "",
  }),
  methods: {
    bottomNavBar(view) {
      switch (view) {
        case "news":
          this.activeView = 'news'
          this.$router.push({name: 'infoCenter', path:"/infocenter"})
          break;
        case "building":
          this.activeView = 'building'
          this.$router.push({name: 'building', path:"/buildings"})
          break;
        case "bookings":
          this.activeView = 'bookings'
          this.$router.push({name: 'bookings', path:"/bookings"})
          break;
          case "incidentReports":
          this.activeView = 'incidentReports'
          this.$router.push({name: 'incidentReports', path:"/incidentreports"})
          break;
        case "menu":
          this.activeView = 'menu'
          this.$router.push({name: 'menu', path:"/menu"})
          break;
        default:
          break;
      }
    }
  },
  created() {
    console.log(window)
    this.activeView = 'news';
  },
  mounted()
  {
    if(this.$route.path?.includes('infocenter'))
    {
      this.view = "news";
    }
    else if(this.$route.path?.includes('buildings'))
    {
      this.view = "building";
    }
    else if(this.$route.path?.includes('bookings'))
    {
      this.view = "bookings";
    }
    else if(this.$route.path?.includes('menu'))
    {
      this.view = "menu";
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
