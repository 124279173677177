import router from "@/router/index.js";

import { ApiFactory } from "@/services/API.js";
import { OAuthService } from "@/services/OAuthService.js";
import AuthCallback from "@/views/auth/AuthCallback.vue";
import LoggedOut from "@/views/auth/LoggedOut.vue";
import Unauthorized from "@/views/auth/Unauthorized.vue";
import Gdpr from "@/components/Gdpr.vue";

class AuthRoutesService {

	constructor() {
		this.authroutes = [];
	}

	initialize() {
		router.addRoutes([
			{
				path: "/oauth2/code/callback",
				name: "auth-callback",
				component: AuthCallback,
				meta: {
					authRoute: true
				}
			},
			{
				path: "/unauthorized-email",
				name: "unauthorized",
				component: Unauthorized,
				meta: {
					authRoute: true
				}
			},
			{
				path: "/logged-out",
				name: "logged-out",
				component: LoggedOut,
				meta: {
					authRoute: true
				}
			},
			// gdpr is not auth, but follows the same logic of not requiring auth (else, we would have a recursive call)
			{
				path: "/gdpr",
				name: "gdpr",
				component: Gdpr,
				meta: {
					authRoute: true
				}
			},
		]);

		router.beforeEach((to, from, next) => {

			let logInRequired = true;
			if (to && to.meta && to.meta.authRoute) {
				logInRequired = false;
			}

			if (logInRequired) {
				OAuthService.checkExpiration();
				if (!OAuthService.isLoggedIn()) {
					let authUrl;
					if (to && to.fullPath) {
						authUrl = to.fullPath;
					}
					OAuthService.logIn(authUrl);
					next(false);
				} else {
					if (!ApiFactory.isInitialized()) {
						ApiFactory.initialize().then(() => {
							next();
							// GdprService.performPostAuthCheck();
						});
					} else {
						next();
					}
				}
			} else {
				next();
			}

		});
	}

}

let instance = new AuthRoutesService();

export { instance as AuthRoutesService };
