import ClientOAuth2 from "client-oauth2"; // https://github.com/mulesoft/js-client-oauth2
import moment from "moment";
import router from "@/router";
import HomeView from "@/views/HomeView.vue"
import configFile from '../../configs/config.json'

// this library can check jwt headers etc, but it seems that it has trouble decoding "ß"
// so now I will deactivate it and use a replacement
// let jwt = jwtJsDecode.jwtDecode(this.token);
// const jwtJsDecode = require("jwt-js-decode");

const TOKEN_KEY = "pinestack.token";
const PRE_AUTH_URL_KEY = "pinestack.pre-auth-url";
let checkLoginEmails = false;


const path = location.protocol + "//" + location.host;
var config = {
	clientId: configFile.CONNECTION.AUTH.CLIENT_ID,
	redirectUri: path + "/oauth2/code/callback",
	authorizationUri: configFile.CONNECTION.AUTH.AUTHORIZATION_URI,
	accessTokenUri: configFile.CONNECTION.AUTH.ACCESS_TOKEN_URI,
	scopes: [],
	query: {
		response_type: "token",
		nonce: (new Date()).getTime(),
	}
}

let lib = new ClientOAuth2(config);

// https://stackoverflow.com/a/38552302
function parseJwt(token) {
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
		return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(""));

	return JSON.parse(jsonPayload);
};

class OAuthService {

	constructor() {
		this.token = sessionStorage[TOKEN_KEY];
	}

	logIn(url) {
		if (this.isLoggedIn()) {
			return Promise.resolve(this.token);
		} else {
			let preAuthUrl = url;
			if (!preAuthUrl) {
				preAuthUrl = window.location.pathname + window.location.hash;
			}
			sessionStorage[PRE_AUTH_URL_KEY] = preAuthUrl;

			window.location = lib.token.getUri();
			return new Promise((resolve, reject) => {
				// do nothing
				// window.location should redirect away
			});
		}
	}

	callback() {
		let uri = window.location.href;

		lib.token.getToken(uri).then((user) => {
			// for open id connect:
			// this.token = user.data.id_token;
			// for token auth
			this.setToken(user.accessToken);

			let userObject = this.getUserProfile();

			let loginEmailFound = false;

			if (checkLoginEmails && !loginEmailFound) {
				this.signOut(false);
				if (window.pinestack.WFE.INVALID_EMAIL_REDIRECT) {
					window.location = window.pinestack.WFE.INVALID_EMAIL_REDIRECT;
				} else {
					router.push({ name: "unauthorized" });
				}
			} else if (!sessionStorage[PRE_AUTH_URL_KEY]) {

				router.push("/");
			} else {
				let url = sessionStorage[PRE_AUTH_URL_KEY];

				router.push(url);
			}

		}).catch((e) => {
			console.error(e);
			//router.push({ name: "unauthorized" });
		}).finally(() => {

		});
	}

	isLoggedIn() {
		let isLoggedIn = !!this.token;
		this.checkExpiration();
		return isLoggedIn;
	}

	signOut(redirect) {
		this.token = null;
		delete sessionStorage[TOKEN_KEY];
		if (redirect !== false) {
			router.push({ name: "logged-out" });
			if (this.preferOfflineToken()) {
				// only completely sign out the user (with clearing the tokens) if the user also sees the "logged out screen"
				// this way we ensure that the long-running refresh token is only cleared when the user actually intented to throw the user out
				// as apposed to "signing the user out" before attempting to refresh the token
				OfflineAuthService.clearTokens();
				this.resetUsingOfflineToken();
			}
		}
	}

	logOut() {
		this.token = null;
		delete sessionStorage[TOKEN_KEY];
		router.push({name:HomeView})
	}

	getUserProfile() {
		let user = {};
		if (this.token) {
			// this library can check jwt headers etc, but it seems that it has trouble decoding "ß"
			// so now I will deactivate it and use a replacement
			// let jwt = jwtJsDecode.jwtDecode(this.token);
			let jwt = {
				payload: parseJwt(this.token)
			}
			user.name = jwt.payload.displayName;
			if (!user.name) {
				user.name = jwt.payload.name;
			}
			if (user.name && user.name.indexOf(",") > 0) {
				user.name = user.name.replace(",", "");
			}
			user.email = jwt.payload.email ?? jwt.payload.upn;
		}

		return user;
	}

	checkExpiration(withTimeframe) {
		if (this.token) {
			// this library can check jwt headers etc, but it seems that it has trouble decoding "ß"
			// so now I will deactivate it and use a replacement
			// let jwt = jwtJsDecode.jwtDecode(this.token);
			let jwt = {
				payload: parseJwt(this.token)
			};
			let ttl = jwt.payload.exp - moment().unix();
			let timeFrame = 60 * 10; // token is valid for less than 10 minutes or not valid anymore
			if (withTimeframe === false) {
				timeFrame = 0; // token is not valid anymore
			}
			if (ttl < timeFrame) {
				this.signOut(false);
				this.logIn();
			}
		}
	}

	getToken() {
		return this.token;
	}

	getParsedToken() {
		if (!this.token) {
			return undefined;
		} else {
			return parseJwt(this.token);
		}
	}


	setToken(token) {
		this.token = token;
		sessionStorage[TOKEN_KEY] = this.token;
	}
}

let instance = new OAuthService();

export { instance as OAuthService };
