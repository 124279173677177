import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import { OAuthService }		 from '@/services/OAuthService.js'
import { AuthRoutesService }		from "@/services/AuthRoutesService.js";

Vue.config.productionTip = false

// const path = location.protocol + "//" + location.host;
// console.log(!localStorage.getItem('token'))
// if(!localStorage.getItem('token'))
// {
//   OAuthService.logIn(path).then((token) => {
//     localStorage.setItem('token', token)
//   });
// }
AuthRoutesService.initialize();



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
