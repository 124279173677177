import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from "@/views/HomeView.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    redirect:{name:'building'}
  },
  {
    path: '/infocenter',
    name: 'infoCenter',
    component: () => import(/* webpackChunkName: "about" */ '../components/InfoCenter.vue')
  },
  {
    path: '/buildings',
    name: 'building',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/Building.vue'),
   
  },
  {
    path: '/building/:building_id/poicategories/:category_id/pois',
    name: 'poisList',
    component: () => import('../components/PoisList.vue')
  },
  {
    path: '/building/:building_id/pois/:poi_id',
    name: 'poiDetails',
    component: () => import('../components/PoiDetails.vue')
  },
  {
    path: '/bookings',
    name: 'bookings',
    component: () => import('../components/Bookings.vue')
  },
 
  {
    path: '/incidentreports',
    name: 'incidentReports',
    component: () => import('../components/IncidentReports.vue')
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../components/Menu.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
